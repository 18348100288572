

import * as CommandeService from '../services/commandeEvent.service'; 

export const GET_ALL_COMMANDESEVENT = 'GET_ALL_COMMANDESEVENT';
export const GET_COMMANDEEVENT_BY_ID = 'GET_COMMANDEEVENT_BY_ID';
export const ADD_COMMANDEEVENT = 'ADD_COMMANDEEVENT';
export const UPDATE_COMMANDEEVENT = 'UPDATE_COMMANDEEVENT';
export const DELETE_COMMANDEEVENT = 'DELETE_COMMANDEEVENT';


export const getCommandes = () => async (dispatch) => {
    try {
        const data = await CommandeService.getAllCommandes();
        dispatch({ type: GET_ALL_COMMANDESEVENT, payload: data });
        return { success: true, data };
    } catch (error) {
        console.error("Erreur lors de la récupération des commandes :", error);
        return { success: false, error };
    }
};

export const getCommandeById = (id) => async (dispatch) => {
    try {
        const data = await CommandeService.getCommandeById(id);
        dispatch({ type: GET_COMMANDEEVENT_BY_ID, payload: data });
        return { success: true, data };
    } catch (error) {
        console.error("Erreur lors de la récupération du commande par ID :", error);
        return { success: false, error };
    }
};



export const addCommande = (data) => async (dispatch) => {
    try {
        const addedCommande = await CommandeService.addCommande(data);
        dispatch({ type: ADD_COMMANDEEVENT, payload: addedCommande });
        return { success: true, data: addedCommande };
    } catch (error) {
        console.error("Erreur lors de l'ajout du commande :", error);
        return { success: false, error };
    }
};

export const updateCommande = (id, data) => async (dispatch) => {
    try {
        const updatedCommande = await CommandeService.updateCommande(id, data);
        dispatch({ type: UPDATE_COMMANDEEVENT, payload: updatedCommande });
        return { success: true, data: updatedCommande };
    } catch (error) {
        console.error("Erreur lors de la mise à jour du commande :", error);
        return { success: false, error };
    }
};

export const deleteCommande = (id) => async (dispatch) => {
    try {
        await CommandeService.deleteCommande(id);
        dispatch({ type: DELETE_COMMANDEEVENT, payload: id });
        return { success: true };
    } catch (error) {
        console.error("Erreur lors de la suppression du commande :", error);
        return { success: false, error };
    }
};

